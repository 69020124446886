@import './fonts.scss';
body,
.p-component {
    font-family: Montserrat !important;
    overflow-x: hidden;
}

.text-red,
.text-red:hover {
    color: #ff0000 !important;
}

.cursor-pointer {
    cursor: pointer;
}

// slick-carousel custom styling
.slick-dots {
    padding-top: 10px !important;
}
.slick-dots li button::before {
    content: '' !important;
    opacity: 1 !important;
    background-color: #ffe5e5;
    border-radius: 100%;
    width: 12px !important;
    height: 12px !important;
    // color: #ef2b30;
}

.slick-dots li.slick-active button::before {
    background: #ef2b30;
}

.no-scroll {
    overflow-y: hidden;
}
